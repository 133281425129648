import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyStrikeSocialTemplate = ({ image }) => {
  return (
    <React.Fragment>
      {" "}
      <CaseStudyHeaderSection
        title="Strike Social"
        description="An upstart social marketing brand rises in a saturated market through Hook Points"
        image={image}
      />
      <CaseStudyDescriptionSection
        list={
          <ul>
            <li>
              We advised the company from its early stage of a few employees
              managing $300,000 in media spend for their clients to{" "}
              <span>a multimillion dollar market leader</span>
            </li>
            <li>
              Provided insight and strategy to restructure the company for{" "}
              <span>maximum performance and growth</span> while creating strong
              Hook Points to <span>attract clients</span>
            </li>
            <li>
              Increased media spend managed to $100 million in spend in just
              a few short years while{" "}
              <span>
                drawing top-level talent, investors, and advisors, including
                major clientele
              </span>{" "}
              such as <span>Disney, XBox,</span> and <span>Fox</span>
            </li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-lala-anthony"}
        text="Want to find out more about how we drove Strike Social’s business and brand?"
        subText=" Apply to work with us"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyStrikeSocialTemplate
