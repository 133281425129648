import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import CaseStudyStrikeSocialTemplate from "../modules/caseStudy/CaseStudyStrikeSocial.template"

const CaseStudyStrikeSocial = ({ data }) => {
  return (
    <Layout>
      <SEO title="Case Study Strike Social" />
      <CaseStudyStrikeSocialTemplate image={data.caseStudySocialStrike.edges} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    caseStudySocialStrike: allFile(
      filter: { relativeDirectory: { eq: "caseStudySocialStrike" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default CaseStudyStrikeSocial
